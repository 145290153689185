import * as React from 'react';
import Cookies from 'js-cookie';

const CleanCookies = () => {
  const cookies = Cookies.get();
  
  try {
    Object.keys(cookies).forEach(key => {
      // to support more than one, use pipe to for or:  /^amcv|^_hp/i
      if(key.match(/^_hp/i)) {
        Cookies.remove(key, { path: '/', domain: '.bcg.com' });
      }
    })
  } catch (ex) {
    console.error('OKTA clean cookies', ex)
  }
  return <div id="clean-cookies"></div>
}

export default CleanCookies;