import React from 'react';
import { Button } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { THEME } from 'src/global-styles';

const invertedStyles = css`
  background: ${({ theme }) => theme.textColor};
  color: ${({ theme }) => theme.accentColor};
`;

const dashedStyles = css`
  border: 2px dashed ${({ theme }) => theme.accentColor}; 
`;

const actionStyles = css`
  width: 40px;
  height: 40px;
  font-size: 10px;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 50%;
  outline: 0;
  padding: 0;
`;

const disabledStyles = css`
  opacity: 0.45 !important;
  cursor: inherit;
`;

const StyledButton = styled(({ rounded, dashed, action, ...rest }) => <Button {...rest} />)`
  &.ui.button {
    font-family: ${({ theme }) => theme.font};
    color: ${({ theme }) => theme.textColor};
    font-size: ${props => props.fontSize};
    background: ${({ theme }) => theme.accentColor};
    border-radius: ${props => props.rounded === true ? '5px' : '0px'};
    border: ${({ theme }) => '2px solid ' + theme.accentColor};
    padding: ${props => props.padding};
    cursor: pointer;

    ${props => props.inverted ? invertedStyles : ''}
    ${props => props.dashed ? dashedStyles : ''}
    ${props => props.action ? actionStyles : ''}
    ${props => props.disabled ? disabledStyles : ''}

    &:hover {
      opacity: 0.75 !important;

      .icon {
        opacity: 1;
      }
    }

    .icon {
      margin: auto !important;
      height: auto;
    }
  }
`;


StyledButton.defaultProps = {
  theme: {
    ...THEME,
    textColor: THEME.COLORS.$white,
  },
  fontSize: '12px',
  padding: '5px 18px',
  inverted: false,
  rounded: true,
  disabled: false
};

export default StyledButton;