import React from 'react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';
import Button from 'components/Button/Button';
import Image from 'components/Image/Image';

export const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  font-family: ${THEME.FONTS.$sansreg};
  color: ${THEME.COLORS.$graydarkest};

  h3 {
    font-size: 24px;
    color: ${THEME.COLORS.$graydarkest};
  }
`;

export const TheButton = styled(({ padTop, ...rest }) => <Button {...rest} />)`
  &&&& {
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: 12px;
    margin-top: 16px;
    padding: 10px 24px;
    display: block;
    line-height: 20px;
    height: auto;
    color: ${THEME.COLORS.$bcggreenbluegreen};

    ${({ padTop }) => padTop && `
      margin-top: 24px;
    `}
  }
`;

export const Expert = styled(Image)`
`;
