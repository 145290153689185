import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

// dark theme?
// other mods?
// how many colors to define?
// primary, secondary, accent, or just colors?
// invert worth it when this is a class you can just use?
// export semantic components? (will that make bundler bigger)
// overridability?

const StyledTab = styled(Tab)`
  background: ${props => props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  font-family: ${props => props.theme.font};
  
  .ui.tabular.menu {
    font-family: ${props => props.theme.FONTS.$sansbold};
    font-weight: bold;
    border-bottom: 2px solid ${THEME.COLORS.$graydark};
    background-color: ${props => props.theme.secondaryColor};

    .item {
      color: inherit;
      border: 0;
      position: relative;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      padding: 16px 24px 12px;
      margin: 0;
      transition: all 0.25s;

      &::after {
        transition: all 0.2s;
        content: '';
        position: absolute;
        width: 100%;
        background-color: ${props => props.theme.primaryColor};
        height: 2px;
        bottom: -2px;
        left: 0;
        transform: scaleX(0);
      }
    }

    .active.item {
      background: inherit;
      color: ${props => props.theme.accentColor};
      border: 0;
      position: relative;
      margin: 0;

      &::after {
        background-color: ${props => props.theme.accentColor};
        transform: scaleX(1);
      }
    }
  }

  .active.tab {
    background: inherit;
  }

  .ui.attached.segment {
    border: 0;
  }
`;

StyledTab.defaultProps = {
  theme: THEME
}

export default StyledTab;
