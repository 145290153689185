import { Card } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledCard = styled(Card)` 
  &.ui.card {
    margin: 18px 0 0;
    background: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.font};

    .content {
      color: ${props => props.theme.textColor};
      border-top: 1px solid ${props => props.theme.secondaryColor} !important;

      &:first-child {
        border-top: none !important;
      }

      p {
        margin: 0;
      }

      .description, .header {
        color: inherit;
        font-family: inherit;
      }
    }
  }

`;

StyledCard.defaultProps = {
  theme: THEME
};

export default StyledCard;