import styled from 'styled-components';
import { THEME } from 'src/global-styles';
import { Button, Col } from 'src';

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2500;
  background-color: rgba(0,0,0,0.6);
  opacity: 0;
  transition: all 200ms ease-out;

  ${({ initialstyle }) => initialstyle && `
    opacity: 0;
  `}

  ${({ showtransition }) => showtransition && `
    opacity: 1;
  `}
`

export const ContentWrapper = styled.div`
  background-color: rgba(3,82,45,0.6); // equiv #03522D
  width: 100%;
  padding: 100px 0px;
  color: ${THEME.COLORS.$white};
  height: auto;
`

export const Content = styled.div`
  width: 100%;
  color: ${THEME.COLORS.$white} !important;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  font-family: ${THEME.FONTS.$sansreg};

  h1, h2, h3, h4 {
    font-family: ${THEME.FONTS.$serifbold};
    color: ${THEME.COLORS.$white} !important;
    font-weight: bold;
    text-transform: uppercase;
  }

  h1 {
    font-size: 32px;
    line-height: 60px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 30px;
  }

  a, a:visited, a:hover, a:active{
    color: white;
    text-decoration: underline;
  }
`

export const ActionButton = styled(Button)`
  &&& {
    background-color: ${THEME.COLORS.$white};
    color: ${THEME.COLORS.$graydarkest};
    border: 0;
    font-size: 12px;
    height: auto;
    padding: 10px 24px;
    text-transform: uppercase;
    font-family: ${THEME.FONTS.$sansbold};
    margin-top: 24px;
  }
`