import { Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledMessage = styled(Message)`
  &.ui.message {
    background: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.textColor};

    .header {
      font-family: inherit;
    }
  }
`;

StyledMessage.defaultProps = {
  theme: THEME
};

export default StyledMessage;
