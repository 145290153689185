// if different theme values are specified, import and override?
// do this at the component level by ...
// have these values be the dfault theme values and then allow different values to be specified....????
// how will these values be provided?

const COLORS = {
  $bcggreendark: '#00532F',
  $bcggreen: '#177B57',
  $bcggreenbluegreen: '#3EAD93',
  $bcggreenlight: '#32C77F',
  $bcgyellow: '#E4EF39',
  $bcgred: '#D50000',
  $bcgblue: '#00BCD4',
  $bcgbluelight: '#88C0FE',
  $bcgbluedark: '#1E455E',
  $bcgorange: '#FF7002',
  $black: '#000000',
  $graydarkest: '#333333',
  $graydarker: '#707070',
  $graydark: '#B7B7B7',
  $gray: '#D9D9D9',
  $graylight: '#F2F2F2',
  $graylighter: '#FAFBFB',
  $graylightest: '#FAFAFA',
  $white: '#FFFFFF',
  $tabmenu: '#F5F5F5',
  $transparent: 'rgba(0, 0, 0, 0)',
  $checkboxgray: '#979797',
  $notifyerror: '#FAE6E6',
  $notifyerrorhighlight: '#D50000',
  $notifyinfo: '#EAF7F9',
  $notifyinfohighlight: '#D50000',
  $notifysuccess: '#EDF5D7',
  $notifysuccesshighlight: '#4CAF50',
  $notifywarn: '#FFF6D0',
  $notifywarnhighlight: '#FF9800',
  $ratingabsent: '#ECE3E3',
  $ratingpresent: '#F8CE0B',
  $boxshadow1: '0 1px 1px 0 rgba($black, 0.14), 0 2px 1px -1px rgba($black, 0.12), 0 1px 3px 0 rgba($black, 0.2)',
  $boxshadow2: '0 6px 10px 0 rgba($black, 0.14), 0 1px 18px 0 rgba($black, 0.12), 0 3px 5px -1px rgba($black, 0.2)',
  $modalwhite: 'rgba($white, 0.95)'
};

const FONTS = {
  $sansreg: 'HendersonBCGSans-Regular, Arial, Helvetica, sans-serif',
  $sansbold: 'HendersonBCGSans-Bold, Arial, Helvetica, sans-serif',
  $sanslight: 'HendersonBCGSans-Light, Arial, Helvetica, sans-serif',
  $serifbold: 'HendersonBCGSerif-Bold, Georgia, "Times New Roman", serif'
};

const BREAKPOINTS = {
  xs: 0,
  sm: '576px',
  md: '667px', // 667 iphone landscape, ipad in portrait (768)
  lg: '1024px', // 1024 ipad in landscape
  xl: '1168px', // 1168 max width, laptop
  zoom150: '424px',
  zoom125: '508px',
  zoom110: '578px',
};

const SIZE = (pixel) => `${pixel / 16}rem`;

const MEDIA_QUERIES = {
  MD: '@media (min-width: 667px)', // iphone landscape, ipad in portrait
  LG: '@media (min-width: 1024px)', // ipad in landscape, max width
  // XL: '@media (min-width: 1168px)' // max width, laptop
};

export const THEME = {
  primaryColor: COLORS.$graylightest,
  secondaryColor: COLORS.$gray,
  accentColor: COLORS.$bcggreenbluegreen,
  textColor: COLORS.$graydarkest,
  font: FONTS.$sansreg,
  COLORS,
  FONTS,
  BREAKPOINTS,
  MEDIA_QUERIES,
  SIZE
};