import { Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledLabel = styled(Label)`
  &.ui.label {
    font-family: ${props => props.theme.FONTS.$sansbold};
    font-size: ${props => props.fontSize};
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.textColor};
  }
`;

StyledLabel.defaultProps = {
  theme: {
    ...THEME,
    primaryColor: THEME.COLORS.$graylight,
    textColor: THEME.COLORS.$graydarkest,
  },
  fontSize: 'inherit'
};

export default StyledLabel;