import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';
import PlaceHolder from 'components/PlaceHolder/PlaceHolder';

const slideLoaderClasses = {
  grid_view: 'slide-loader--result',
  scrubber: 'slide-loader--scrubber',
  scrubber_highlight: 'slide-loader--scrubber-highlight',
  overlay_single: 'slide-loader--overlay-single',
  overlay_multi: 'slide-loader--overlay-multi'
};

const SlideLoader = ({ flavor, className }) => {
  return (
    <PlaceHolder className={className}>
      <PlaceHolder.Image rectangular />
    </PlaceHolder>
  );
};

SlideLoader.propTypes = {
  flavor: PropTypes.string
};

SlideLoader.defaultProps = {
  flavor: ''
};

const StyledSlideLoader = styled(SlideLoader)`
  .slide-header {
    background-image: linear-gradient(90deg, #F4F4F4 0, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
    width: 80%;
    height: 24px;
    border-radius: 100px;
    animation: shine-lines 2s infinite ease-out;
    margin-bottom: 24px;
  }

  .slide-divider {
    background-image: linear-gradient(90deg, #F4F4F4 0, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
    width: 100%;
    height: 2px;
    animation: shine-lines 2s infinite ease-out;
    margin-bottom: 24px;
  }

  .slide-text {
    background-image: linear-gradient(90deg, #F4F4F4 0, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
    width: 100%;
    height: 12px;
    border-radius: 100px;
    animation: shine-lines 2s infinite ease-out;
    margin-bottom: 24px;
  }

  .slide-text--short {
    background-image: linear-gradient(90deg, #F4F4F4 0, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
    width: 60%;
    height: 12px;
    border-radius: 100px;
    animation: shine-lines 2s infinite ease-out;
    margin-bottom: 24px;
  }

  &.slide-loader--result,
  &.slide-loader--overlay-multi,
  &.slide-loader--overlay-single {
    width: 100%;
    padding: 16px;

    @media (max-width: ${THEME.BREAKPOINTS.lg}) {
      height: 265px;
    }

    @media (max-width: ${THEME.BREAKPOINTS.md}) {
      height: 192px;
    }
  }

  &.slide-loader--overlay-single {
    width: 100%;
    height: 480px;
  }

  &.slide-loader--overlay-multi {
    height: 110px;
      
    .slide-header {
      height: 10px;
      margin-bottom: 10px;
    }

    .slide-divider {
      height: 2px;
      margin-bottom: 10px;
    }

    .slide-text {
      height: 7px;
      margin-bottom: 10px;
    }

    .slide-text--short {
      height: 5px;
      margin-bottom: 10px;
    }
  }

  &.slide-loader--scrubber {
    padding: 4px;
    width: 100%;
    height: 66px;
    background-color: ${THEME.COLORS.$white};

    .slide-header {
      height: 8px;
      margin-bottom: 4px;
    }

    .slide-divider {
      height: 2px;
      margin-bottom: 4px;
    }

    .slide-text {
      height: 4px;
      margin-bottom: 4px;
    }

    .slide-text--short {
      height: 2px;
      margin-bottom: 4px;
    }
  }

  &.slide-loader--scrubber-highlight {
    padding: 4px;
    width: 100%;
    height: 110px;
    background-color: ${THEME.COLORS.$white};

    .slide-header {
      height: 16px;
      margin-bottom: 8px;
    }

    .slide-divider {
      height: 4px;
      margin-bottom: 8px;
    }

    .slide-text {
      height: 8px;
      margin-bottom: 8px;
    }

    .slide-text--short {
      height: 2px;
      margin-bottom: 4px;
    }
  }

  @keyframes shine-lines {
    0% { background-position: 0; }
    40% { background-position: 250px; }
    100% { background-position: 250px; }
  }
`;

StyledSlideLoader.defaultProps = {
  theme: {
    ...THEME,
    $slideloaderbackground: 'linear-gradient(90deg, #F4F4F4 0, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px)'
  }
}

export default StyledSlideLoader;