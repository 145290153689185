import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledForm = styled(Form)`
  &.ui.form {
    background: ${props => props.theme.secondaryColor};
    font-family: ${props => props.theme.font};
    
    label { 
      color: ${props => props.theme.textColor};
    }
  }
`;

StyledForm.defaultProps = {
  theme: THEME
};

export default StyledForm;
