import Slider from 'react-rangeslider';
import styled from 'styled-components';

const StyledRangeSlider = styled(Slider)`
  .rangeslider__labels {
  .rangeslider__label-item {
    font-family: HendersonBCGSans-Regular, Arial, Helvetica, sans-serif;
    color: #333333;
    font-size: 12px;
    top: 5px;
    transform: none;

    &:hover {
      font-family: HendersonBCGSans-Bold, Arial, Helvetica, sans-serif;
      color: #3EAD93;
    }
  }
}

/**
* Rangeslider
*/
&.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;

  &,
  .rangeslider__fill {
    display: block;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
  }
  .rangeslider__handle {
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
    .rangeslider__active {
      opacity: 1;
    }
  }

  .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-size: 14px;
    transition: all 100ms ease-in;
    border-radius: 4px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    span {
      margin-top: 12px;
      display: inline-block;
      line-height: 100%;
    }
    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    }
  }
}

/**
* Rangeslider - Horizontal slider
*/
&.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
  .rangeslider__fill {
    height: 100%;
    background-color: #7cb342;
    border-radius: 10px;
    top: 0;
  }
  .rangeslider__handle {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    &:after {
      content: ' ';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 6px;
      left: 6px;
      border-radius: 50%;
      background-color: #dadada;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset,
        0 -1px 3px rgba(0, 0, 0, 0.4) inset;
    }
  }
  .rangeslider__handle-tooltip {
    top: -55px;
    &:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(0, 0, 0, 0.8);
      left: 50%;
      bottom: -8px;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
  .rangeslider-vertical & {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;

    .rangeslider__label-item {
      position: absolute;
      transform: translate3d(0, -50%, 0);

      &::before {
        content: '';
        width: 10px;
        height: 2px;
        background: black;
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }
    }
  }

  .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
}

  &.rangeslider {
    margin: 10px 0;
  }

  &.rangeslider-horizontal { 
    height: 8px;
    width: 100%;

    .rangeslider__fill {
      background-color: #3EAD93;
    }

    .rangeslider__handle {
      display: none;
    
      &::after {
        display: none;
      }
    }
  }

  .rangeslider__labels {
    margin-top: -3px;

    .rangeslider__label-item {
      font-family: HendersonBCGSans-Regular, Arial, Helvetica, sans-serif;
      color: #333333;
      font-size: 12px;
      top: 5px;
      transform: none;
      margin-left: -4px;

      :hover {
        font-family: HendersonBCGSans-Bold, Arial, Helvetica, sans-serif;
        color: #3EAD93;

        :before {
          content: 'Past ';
        }
      }

      :first-child {
        :hover {
          :before {
            content: '';
          }
        }
      }
    }
  }
`;

export default StyledRangeSlider;