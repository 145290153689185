import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox {
    min-height: ${props => props.size};
    min-width: ${props => props.size};
    line-height: ${props => props.size};

    .box::before,
    label::before,
    .box::after,
    label::after {
      height: ${props => props.size};
      width: ${props => props.size};
    }

    &.checked .box::before,
    &.checked label::before,
    &.indeterminate .box::before,
    &.indeterminate label::before {
      background: ${({ theme }) => theme.accentColor} !important;
      border: 1px solid ${({ theme }) => theme.accentColor};
    }

    .box::after,
    label::after {
      color: ${({ theme }) => theme.textColor} !important;
      font-size: ${props => props.fontSize};
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &.ui.checkbox {
        &.checked .box::before,
        &.checked label::before,
        &.indeterminate .box::before,
        &.indeterminate label::before {
          background-color: ${({ theme }) => theme.COLORS.$bcggreenbluegreen} !important;
        }

        label::after {
          background-color: ${({ theme }) => theme.COLORS.$bcggreenbluegreen};
        }
      }
    }
  }
`;

StyledCheckbox.defaultProps = {
  theme: {
    ...THEME,
    textColor: THEME.COLORS.$white
  },
  size: '14px',
  fontSize: '12px',
};

export default StyledCheckbox;