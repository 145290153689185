import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const Heading = styled(Header)`
  &, &.ui.header {
    font-family: ${props => props.theme.font};
  }
`;

Heading.defaultProps = {
  theme: THEME
};

export default Heading;
