import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown, &.ui.dropdown.visible {
    background: ${props => props.theme.primaryColor};

    & > .default.text, & > .text:not(.default) {
      color: ${props => props.theme.textColor};
      font-family: ${props => props.theme.font};
    }

    .dropdown.icon {
      color: ${props => props.theme.textColor};
    }

    .menu {
      .item {
        background: ${props => props.theme.primaryColor};
        color: ${props => props.theme.textColor};
        font-family: ${props => props.theme.font};
      }
    }
  }
`;

StyledDropdown.defaultProps = {
  theme: THEME
};

export default StyledDropdown;
