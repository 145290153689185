import { Confirm } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledConfirm = styled(Confirm)`
  &.ui.modal {
    position: static !important;
    background-color: ${props => props.theme.primaryColor} !important;

    > .content {
      background-color: ${props => props.theme.primaryColor};
      font-family: ${props => props.theme.font};
      color: ${props => props.theme.textColor};
    }

    > .actions {
      background-color: ${props => props.theme.secondaryColor};
      font-family: ${props => props.theme.font};
      color: ${props => props.theme.textColor};

      > .ui.button {
        border: 0;
        font-family: ${props => props.theme.font};
        font-weight: normal;
        font-size: 20px;
        letter-spacing: 0.7px;
        text-align: center;
        color: ${props => props.theme.textColor};
        background: none;
      }

      > .button.primary {
        color: ${props => props.theme.accentColor};
      }
    }
  }

  &.unpinAlertBox {
    .actions>.button {
      font-size: 15px !important;
      text-transform: none;
    }
  }
`;

StyledConfirm.defaultProps = {
  theme: {
    ...THEME
  }
};



export default StyledConfirm;