import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledMenu = styled(Menu)`
  &.ui.menu {
    background: ${props => props.theme.secondaryColor};
    
    .item {
      background: ${props => props.theme.primaryColor};
      color: ${props => props.theme.textColor};
      font-family: ${props => props.theme.font};

      &:before {
        background: ${props => props.theme.secondaryColor};
      }

      &.active {
        background: ${props => props.theme.secondaryColor};
      }
    }
  }
`;

StyledMenu.defaultProps = {
  theme: {
    ...THEME,
    primaryColor: THEME.COLORS.$white,
    secondaryColor: THEME.COLORS.$graylight
  }
};

export default StyledMenu;
