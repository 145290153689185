import { Popup } from 'semantic-ui-react';
import React from 'react';
import CONFIG from 'src/config';
import styled, { css } from 'styled-components';
import { THEME } from 'src/global-styles';

const invertStyles = css`
  &.ui.popup {
    background-color: ${props => props.theme.secondaryColor};
    color: ${props => props.theme.primaryColor};
    border: ${props => props.theme.secondaryColor} 1px solid;

    a {
      color: ${props => props.theme.primaryColor};
    }
  }

  &.ui.bottom.popup::before,
  &.ui.bottom.center.popup::before,
  &.ui.bottom.left.popup::before,
  &.ui.bottom.right.popup::before,
  &.ui.right.popup::before,
  &.ui.top.popup::before,
  &.ui.top.center.popup::before,
  &.ui.top.left.popup::before,
  &.ui.top.right.popup::before {
    box-shadow: none;
    border: ${props => props.theme.secondaryColor};
    background: ${props => props.theme.secondaryColor};
  }
`;

const StyledPopup = styled(Popup)`
  &.ui.popup {
    background-color: ${props => props.theme.primaryColor};
    font-size: 12px;
    min-width: 80px;
    min-height: 32px;
    padding: 8px 16px;
    border-radius: 7px;
    border-color: ${props => props.theme.primaryColor};
    opacity: 0.95;
    color: ${props => props.theme.secondaryColor};
    text-align: center;
    
    a {
      color: ${props => props.theme.secondaryColor};
      text-decoration: underline;
    }
  }

  &.ui.bottom.popup::before,
  &.ui.bottom.center.popup::before,
  &.ui.bottom.left.popup::before,
  &.ui.bottom.right.popup::before,
  &.ui.right.popup::before,
  &.ui.top.popup::before,
  &.ui.top.center.popup::before,
  &.ui.top.left.popup::before,
  &.ui.top.right.popup::before {
    box-shadow: ${props => props.theme.COLORS.$boxshadow1};
    border: ${props => props.theme.primaryColor};
    background: ${props => props.theme.primaryColor};
  }

  ${props => props.invert ? invertStyles : ''}

  &.ui.visible.popup-xs--hide {
    @media (max-width: map-get(${props => props.theme.BREAKPOINTS.$xs})) {
      display: none !important;
    }
  }

  &.ui.visible.popup-sm--hide {
    @media (max-width: map-get(${props => props.theme.BREAKPOINTS.$sm})) {
      display: none !important;
    }
  }

  &.ui.visible.popup-md--hide {
    @media (max-width: map-get(${props => props.theme.BREAKPOINTS.$md})) {
      display: none !important;
    }
  }

  &.ui.visible.popup-lg--hide {
    @media (max-width: map-get(${props => props.theme.BREAKPOINTS.$lg})) {
      display: none !important;
    }
  }

  &.ui.visible.popup-xl--hide {
    @media (max-width: map-get(${props => props.theme.BREAKPOINTS.$xl})) {
      display: none !important;
    }
  }
`;

StyledPopup.defaultProps = {
  theme: {
    ...THEME,
    primaryColor: THEME.COLORS.$black,
    secondaryColor: THEME.COLORS.$white
  }
};

const PopUpWrapper = props => (<StyledPopup data-testid={CONFIG.TEST_IDS.POPUP} {...props} />);

export default PopUpWrapper;