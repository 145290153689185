import React from 'react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

export const LABIcon = styled.div`
  border-radius: 4px;
  background-color: ${props => props.theme.COLORS.$bcggreenlight};
  color: ${props => props.theme.textColor};
  font-family: ${props => props.theme.font};
  font-size: ${props => props.fontSize};
  display: flex;
  justify-content: center;
  align-items: center;
`;

LABIcon.defaultProps = {
  theme: {
    ...THEME,
    textColor: THEME.COLORS.$white
  },
  fontSize: '14px'
}

export default (props) => (<LABIcon {...props}>LAB</LABIcon>);