import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledTable = styled(Table)`
  &.ui.table {
    background-color: ${props => props.theme.primaryColor};
    display: table;
    width: 100%;
    margin: 16px 0;
    border-style: none !important;
  }

  .trow {
    display: table-row;
    position: relative;
    border-style: none !important;
  }

  .tdef {
    background-color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.font};
    font-size: 14px;
    line-height: 56px;
    color: ${props => props.theme.textColor};
    padding-bottom: 8px;
  }

  &.ui.table tr td {
    border-top: 0.1px !important;
    color: ${props => props.theme.textColor};
    font-family: ${props => props.theme.font};
  }


  &.ui.table thead th {
    background-color: ${props => props.theme.secondaryColor};
    font-family: ${props => props.theme.font};
    font-size: ${props => props.fontSize};
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: ${props => props.theme.textColor};
  }
`;

StyledTable.defaultProps = {
  theme: THEME,
  fontSize: '20px'
}

export default StyledTable;
