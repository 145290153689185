import { Progress } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledProgress = styled(Progress)`
  &.ui.progress {
    background: ${props => props.theme.secondaryColor};
    
    &:first-child {
      margin: 0;
    }
    
    .bar {
      background: ${props => props.theme.accentColor};
    }

    &.active.indicating, &.active {
      .bar {
        background: ${props => props.theme.accentColor};
      }
    }
  }
`;

export default StyledProgress;

StyledProgress.defaultProps = {
  theme: {
    ...THEME,
    accentColor: THEME.COLORS.$bcggreenlight
  }
};