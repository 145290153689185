import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ButtonsWithIntro.styles';
import Image from 'components/Image/Image';

const ButtonsWithIntro = ({ icon, title, description, buttons, onButtonClick }) => 
  <Styled.Wrapper>
    {icon && <Image src={icon} alt={title} />}
    <h3>{title}</h3>
    {description && <div>{description}</div>}
    {buttons.map((butt, index) => 
      <Styled.TheButton key={butt.label} inverted onClick={() => onButtonClick(butt)} padTop={index === 0 ? true : false}>{butt.label}</Styled.TheButton>
    )}
  </Styled.Wrapper>

ButtonsWithIntro.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttons: PropTypes.array.isRequired,
  onButtonClick: PropTypes.func.isRequired
}

ButtonsWithIntro.defaultProps = {
  icon: null,
  description: ''
}

export default ButtonsWithIntro;
