import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledModal = styled(Modal)`
  &.ui.modal {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.textColor};
    font-family: ${props => props.theme.font};

    .content, .header {
      background: inherit;
      font-family: inherit;
      color: inherit;
    }

    > .header {
      background: ${props => props.theme.secondaryColor};
    }
  }
`;

StyledModal.defaultProps = {
  theme: THEME
};

export default StyledModal;
