import Cookies from 'js-cookie';
import { v4 as uuidv4} from 'uuid';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Gets the default cookie settings:
 * 
 * _excludes localhost development_
 * ```
 * {
 *  expires: 1, // expires in 1 day,
 *  secure: true,  // secures the cookie to this domain
 *  domain: '.bcg.com' // enables the cookie to be shared across any domain under *.bcg.com
 * }
 * ``` 
 * 
 * @param {String} env - name of the environment this auth is running against, use `'dev'`, `'qa'`, `'stg'`, or `'prod'`.
 * @returns JSON object with the config
 */
export const getCookieOptions = (env) => {
  let options = { expires: 1 };
  if (env !== 'local') { // localhost has some restrictions
    options = Object.assign(options, { secure: true, domain: '.bcg.com' });
  }
  return options;
}

/**
 * Deletes all cookies starting with `okta-`
 * 
 */
export const clearOktaCookies = (env) => {
  console.log('OKTA util clearing okta cookies')
  const allCookies = Cookies.get();
  Object.keys(allCookies).forEach((k) => {
    if (k.indexOf('okta-') === 0) {
      if(env !== 'local') {
        Cookies.remove(k, { domain: '.bcg.com' });
      } else {
        Cookies.remove(k);
      }
    }
  });
};

/**
 * This callback is triggered when the cookie is set
 * 
 * @callback onSetItemCallback
 * @param {String} status - 'success' or 'error'
 * @param {Object} value - data object set to cookie, or error.
 */

/**
 * This callback is triggered when the cookie is retrieved
 * 
 * @callback onGetItemCallback
 * @param {String} status - 'success', 'mismatch environment', or 'error'
 * @param {Object} value - value retrieved, or error.
 */

/**
 * Retrieves the base auth configuration for Okta.
 * @param {String} env - name of the environment this auth is running against, use `'dev'`, `'qa'`, `'stg'`, or `'prod'`.
 * @param {onSetItemCallback} onSetItem - callback when the cookie is set
 * @param {onGetItemCallback} onGetItem - callback when the cookie has been retrieved
 * @returns void
 */
export const authServiceConfig = (env, onSetItem, onGetItem) => ({
  pkce: false,
  scopes: ['profile', 'email', 'openid', 'groups'],
  tokenManager: tokenManager(env, onSetItem, onGetItem)
})

/**
 * The token manager Okta uses for setting and getting cookie values.
 * @param {String} env - name of the environment this auth is running against, use `'dev'`, `'qa'`, `'stg'`, or `'prod'`.
 * @param {onSetItemCallback} onSetItem - callback when the cookie is set
 * @param {onGetItemCallback} onGetItem - callback when the cookie has been retrieved
 * @returns void
 */
export const tokenManager = (env, onSetItem, onGetItem) => ({
  expireEarlySeconds: (30 * 60),
  storage: {
    getItem: (key) => {
      if(env === Cookies.get('okta-environment')) {
        try {
          const allCookies = Cookies.get();
          const value = {};

          Object.keys(allCookies).forEach((k) => {
            if (k.indexOf(key) === 0) {
              value[k.replace(`${key}_`, '')] = JSON.parse(allCookies[k]);
            }
          });
          // console.log('get', key);
          // console.log(value);
          if (onGetItem) onGetItem('success', value);
          return JSON.stringify(value);
        } catch (e) {
          console.error('OKTA tokenManager storage getItem', e);
          if (onGetItem) onGetItem('error', e);
          return null;
        }
      } else {
        console.log('OKTA tokenManager wrong environment, asking for', env, 'but received', Cookies.get('okta-environment'));
        clearOktaCookies();
        if (onGetItem) onGetItem('mismatch environment');
        return null;
      }
    },
    setItem: (key, val) => {
      try {
        const options = getCookieOptions(env);

        const value = JSON.parse(val);

        Object.keys(value).forEach((k) => {
          if(k !== 'idToken') {
            const storageKey = `${key}_${k}`;
            delete value[k].value; // unncessary and makes cookie heavy
            // console.log('OKTA tokenManager set cookies', storageKey, value[k], options);
            Cookies.set(storageKey, value[k], options);
          }
        });

        //we want to save id info separately because the id token will expire faster than access token
        // console.log('OKTA tokenManager set additional cookies');
        try {
          Cookies.set('okta-user-claims', value.idToken.claims, options);
          Cookies.set('okta-environment', env, options);
        } catch (ex) {
          console.error('OKTA tokenManager.siteItem ', ex.toString());
        }

        if(onSetItem) onSetItem('success', value);
      } catch (e) {
        console.error('OKTA tokenManager storage setItem', e);
        if(onSetItem) onSetItem('error', { ...e, val });
      }
    }
  }
});

/**
 * Gets the cookie value `okta-user-claims`
 * 
 * @returns JSON object or `null` if it doesn't exist
 */
export const getUserClaims = () => {
  const claims = Cookies.get('okta-user-claims');
  return !!claims ? JSON.parse(claims) : null;
}

/**
 * Gets the PsId from the cookie
 * 
 * @returns PsId value as a string, currently sent to blank
 */
export const getPsId = () => {
  return ''; // yes blank, leaving this as a util as it may be used to provide a real value in future
}