import React, { memo } from 'react';
import powerpointIcon from 'assets/images/powerpoint.svg';
import excelIcon from 'assets/images/excel.svg';
import wordIcon from 'assets/images/word.svg';
import zipIcon from 'assets/images/zip-folder.svg';
import adobeIcon from 'assets/images/adobe.svg';
import urlIcon from 'assets/images/url.svg';
import miscIcon from 'assets/images/misc-logo.svg';
import LabIcon, { LABIcon } from 'components/LABIcon/LABIcon';
import Image from 'components/Image/Image';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const Attachment = (props) => {
  const { displayName,
    title,
    urlTitle,
    materialURL,
    isLabContent,
    className
  } = props;
  let attachmentText = '';
  if (isLabContent) {
    attachmentText = title;
  } else if (materialURL !== '') {
    attachmentText = urlTitle;
  } else {
    attachmentText = !displayName ? title : displayName;
  }

  let imgs = '';
  if (materialURL.length > 0) {
    imgs = urlIcon;
  } else {
    const extn = displayName.indexOf('.') <= -1 ? '' : displayName.substring(displayName.lastIndexOf('.') + 1).toLowerCase();

    if (extn.search('ppt') !== -1) {
      imgs = powerpointIcon;
    } else if (extn.search('xls') !== -1 || extn === 'csv') {
      imgs = excelIcon;
    } else if (extn.search('zip') !== -1) {
      imgs = zipIcon;
    } else if (extn.search('pdf') !== -1) {
      imgs = adobeIcon;
    } else if (extn.search('doc') !== -1) {
      imgs = wordIcon;
    } else {
      imgs = miscIcon;
    }
  }

  return (
    <div className={`attachment ${className}`}>
      {
        isLabContent ?
          <LabIcon className="attachIcon" />
          :
          <AttachImage><Image className="attachIcon" src={imgs} alt="icon" /></AttachImage>
      }
      <p className="attachTitle">{attachmentText}</p>
    </div>
  );
};

const AttachImage = styled.p`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin: 0;
`;

const StyledAttachment = styled(Attachment)`
  &.attachment {
    display: flex;
    width: 100%;
    align-items: flex-start;

    .attachTitle {
      font-family: ${props => props.theme.font};
      font-size: 16px;
      line-height: 1.3;
      letter-spacing: normal;
      color: ${props => props.theme.textColor};
      overflow-wrap: break-word;
      word-wrap: break-word;
      margin: 0;
      padding-left: 8px;
      overflow: hidden;

      @media (max-width: map-get(${props => props.theme.BREAKPOINTS.$md})) {
        font-size: 13px;
        max-width: calc(100% - 40px);
        padding-right: 8px;
      }

    .attachIcon {
      width: 100%;
      height: 100%;
    }
  }

  ${LABIcon} {
    height: 16px;
    width: 48px;
  }
`;

StyledAttachment.defaultProps = {
  displayName: '',
  title: '',
  urlTitle: '',
  materialURL: '',
  isLabContent: false,
  theme: THEME
};

export default StyledAttachment;