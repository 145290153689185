import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledAccordion = styled(Accordion)`
  &.ui.accordion {
    background: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.textColor};
    font-size: ${props => props.fontSize};

    .title {
      font-family: inherit;
      color: inherit;
      border-top: 1px solid ${props => props.theme.secondaryColor};

      &.active, &:hover {
        color: ${props => props.theme.accentColor};
      }
    }
  }
`;

StyledAccordion.defaultProps = {
  theme: THEME,
  fontSize: '16px'
};

export default StyledAccordion;
