import KeepAlive from "components/KeepAlive/KeepAlive";
import CleanCookies from 'components/CleanCookies/CleanCookies';
import { OktaKeepAliveSubject } from './subject';
import { SUBSCRIBERS } from './subscribers';
import * as utils from 'utils/utils';
import packJson from '../package.json';

window.bcgShared = window.bcgShared || [];
window.bcgShared.push(`${packJson.name} ${packJson.version}`);

export { 
  KeepAlive, 
  CleanCookies,
  OktaKeepAliveSubject,
  SUBSCRIBERS,
  utils
};
