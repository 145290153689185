import React from 'react';
import { Input } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { THEME } from 'src/global-styles';

const underlineStyles = css`
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 2px solid ${props => props.theme.secondaryColor};
  border-radius: 0;

  &:active, &:focus {
    border-bottom: 2px solid ${props => props.theme.accentColor};
  }
`;

const StyledInput = styled(({ underline, ...rest}) => <Input {...rest} />)`
  &.ui.input {
    input {
      background: ${props => props.theme.primaryColor} !important;
      color: ${props => props.theme.textColor} !important;
      font-size: ${props => props.theme.fontSize};
      font-family: ${props => props.theme.font};
      border: 1px solid ${props => props.theme.secondaryColor};

      &:active, &:focus {
        border: 1px solid ${props => props.theme.accentColor};
      }
      
      ${props => props.underline ? underlineStyles : ''};
    }
  }
`;

export default StyledInput;

StyledInput.defaultProps = {
  theme: {
    ...THEME,
    primaryColor: THEME.COLORS.$white,
    secondaryColor: THEME.COLORS.$gray,
    accentColor: THEME.COLORS.$bcggreenbluegreen,
    textColor: THEME.COLORS.$graydarkest,
    fontSize: '12px'
  },
  underline: false
}
