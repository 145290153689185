import { List } from 'semantic-ui-react';
import styled from 'styled-components';
import { THEME } from 'src/global-styles';

const StyledList = styled(List)`
  &.ui.list {
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.textColor};
    background: ${props => props.theme.primaryColor};

    &.divided.list > .item {
      border-top: 1px solid ${props => props.theme.secondaryColor};
    }
  }
`;

StyledList.defaultProps = {
  theme: THEME
};

export default StyledList;